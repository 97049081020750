import React from 'react';

import { useBooleanFeatureFlag } from '@confluence/session-data';
import {
	SPACE_SETTINGS_DETAILS_ANALYTICS_FF,
	SPACE_SETTINGS_DETAILS_ANALYTICS_SOURCE,
	LazySpaceDetailsPage,
} from '@confluence/space-settings';
import { Redirection } from '@confluence/route-manager/entry-points/Redirection';
import { namedRoutes } from '@confluence/named-routes';
import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';

export const SpaceSettingsDetailsRoute = ({ params: { spaceKey } }) => {
	const isSPAPageEnabled = useBooleanFeatureFlag(SPACE_SETTINGS_DETAILS_ANALYTICS_FF);

	if (!isSPAPageEnabled) {
		return <Redirection name={namedRoutes.SPACE_SETTINGS.name} params={{ spaceKey }} />;
	}

	return <LazySpaceDetailsPage spaceKey={spaceKey} />;
};

SpaceSettingsDetailsRoute.NAVIGATION_PARAMS = ({ params: { spaceKey } }) => ({
	Screen: {
		screenEvent: { name: SPACE_SETTINGS_DETAILS_ANALYTICS_SOURCE },
		pageState: { spaceKey },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
