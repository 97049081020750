import { query } from '@confluence/query-preloader-tools';
import { preloadWebItemLocation } from '@confluence/web-item-location/entry-points/preloadWebItemLocation';
import { fg } from '@confluence/feature-gating';

import { preloadSettingsComponentQuery } from './preloadSettingsComponentQuery';
import { AppNavigationUnifiedQuery } from './AppNavigationUnifiedQuery.graphql';

export function preloadAppNavigation() {
	const appNavigationPromises = [
		query({
			query: AppNavigationUnifiedQuery,
		}),
		preloadWebItemLocation({
			location: 'system.header/left',
		}),
	];

	const isOperationsQueryMigrationEnabled = fg('confluence_operation_query_migration');

	if (process.env.REACT_SSR && !isOperationsQueryMigrationEnabled) {
		appNavigationPromises.push(preloadSettingsComponentQuery());
	}
	return Promise.all(appNavigationPromises);
}
